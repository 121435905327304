// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/tmp/6ecd1171/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-me-js": () => import("/tmp/6ecd1171/src/pages/about-me.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-index-js": () => import("/tmp/6ecd1171/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-work-js": () => import("/tmp/6ecd1171/src/pages/my-work.js" /* webpackChunkName: "component---src-pages-my-work-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/tmp/6ecd1171/.cache/data.json")

